
import { useAction, useGetter, useStateAttribute } from '@/common/store/helper';
import { useRoute, useRouter } from 'vue-router';
import { useTheme } from 'vuetify';
import {
  mdiContentCopy, mdiLinkOff,
} from '@mdi/js';
import PegInAccountSelect from '@/pegin/components/create/PegInAccountSelect.vue';
import * as constants from '@/common/store/constants';
import { computed, ref, watch } from 'vue';
import { truncateString } from '@/common/utils';
import { WeiBig } from '@/common/types';
import EnvironmentContextProviderService from '@/common/providers/EnvironmentContextProvider';

export default {
  name: 'TopBar',
  components: {
    PegInAccountSelect,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const themeLight = ref(false);
    const vuetifyTheme = useTheme();

    const account = useGetter<string>('web3Session', constants.SESSION_GET_CHECKSUMMED_ACCOUNT);
    const truncatedAccount = computed(() => truncateString(account.value));

    const balance = useStateAttribute<WeiBig>('web3Session', 'balance');
    const environmentContext = EnvironmentContextProviderService.getEnvironmentContext();

    const walletDataReady = useStateAttribute<boolean>('pegInTx', 'walletDataReady');

    const accountBalance = computed(() => {
      const amount = balance.value.toRBTCString().slice(0, 7);
      return `${amount} ${environmentContext.getRbtcTicker()}`;
    });

    const clearSession = useAction('web3Session', constants.WEB3_SESSION_CLEAR_ACCOUNT);

    function disconnectWallet() {
      clearSession();
      router.push({ name: 'Home' });
    }

    function copyFullAccountAddress() {
      navigator.clipboard.writeText(account.value);
    }

    function goHome() {
      if (route.name !== 'Home') router.push({ name: 'Home' });
    }

    function getLogoSrc() {
      return vuetifyTheme.global.current.value.dark ? require('@/assets/logo-rootstock-white.svg') : require('@/assets/logo-rootstock-black.svg');
    }

    watch(themeLight, (enabledLight) => {
      vuetifyTheme.global.name.value = enabledLight ? 'light' : 'dark';
    });

    return {
      goHome,
      getLogoSrc,
      truncatedAccount,
      disconnectWallet,
      mdiLinkOff,
      mdiContentCopy,
      copyFullAccountAddress,
      themeLight,
      accountBalance,
      balance,
      environmentContext,
      isPeginSelected: computed(() => route.name === 'Create'),
      walletDataReady,
    };
  },
};
